.nav-shuffle {
    @extend .nav;
    @extend .nav-dark;
    @extend .mb-7;
    font-size: 1.5em;
    .nav-link {
        border-bottom: 1px solid $color-bg-lighter;
        height: 84px;
        &.active {
            color: $color-text !important;
            border-color: $color-text;
        }
        &:hover {
            color: $color-text !important;
        }
    }
}