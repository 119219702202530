.navbar-brand {
    max-width: 300px;
}

.container-bg {
    background-color: $color-primary;
}

.lead-container {
    .lead {
        @extend .col-lg-8;
        @extend .col-md-10;
        @extend .px-5;
        @extend .py-7;
        @extend .mx-auto;
        @extend .container-bg;
        @extend .text-white;
        @extend .lead-3;


        @include media-breakpoint-down(sm) {
            font-size: 1em !important;
            padding-top: 25px !important;;
            padding-bottom: 25px !important;;
        }        
    }
}

header {
    // For new navigation
    margin-top: 0 !important;

    // @extend .container;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     // background: linear-gradient(-45deg, #ee7752, #e73c7e, #ee7752, #e73c7e) !important;
    //     background-size: 120% 120% !important;
    // 	opacity: 0.9;
    // }
    &.no-bg {
        &:before {
            background: none !important;
        }
    }

    h1 {
        font-size: 2em;
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 1.5em
        }
    }

    h4 {}
}

.item {
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.colorOverlay {
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: black;
    opacity: .5;
    z-index: 2;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-color: $color-primary;
}

.header {
    @extend .container;

    padding-top: 0;
    padding-bottom: 0;

    &.header-page {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .textBox {
        z-index: 7;
        position: absolute;
        bottom: 0;
    }

    .header-content {
        position: relative;
        height: 700px;
        max-height: 55vh;
        padding-top: 80px;
        padding-bottom: 80px;
        // &:before {
        //     mix-blend-mode: color;
        //     position: absolute;
        //     top:0;
        //     left: 0;
        //     display: block;
        //     width: 100%;
        //     min-height: 100%;
        //     background-color: red;
        //     opacity: .5;
        //     content: '';
        //     z-index: +2;
        // }
        background-size: cover;
    }


    @include media-breakpoint-down(lg) {
        .header-content {
            height: 700px;
        }
    }

    @include media-breakpoint-down(md) {
        .header-content {
            height: 400px;
        }
    }

    @include media-breakpoint-down(sm) {}

    &.header-homepage {
        .header-content {
            padding: 0;
        }

    }

    &.header-default,
    &.header-person,
    &.header-contact {
        .header-content {
            padding-bottom: 0;
        }
    }

    &.header-magazin {
        .header-content {
            height: auto;
        }
    }


    &.header-contact {
        .header-content {
            position: absolute !important;
            top: 0 !important;
            z-index: 999 !important;
        }

        #contactMap {
            z-index: 9;
        }

        .header-content {
            &:after {
                background-color: initial !important;
            }
        }
    }

    // margin-top: $header-height;
    margin-top: 0;

    &.hasSub {
        margin-top: $header-height + $bottom-header-height;
    }

    i,
    em {
        color: $color-primary;
        font-style: normal;
    }

    #contactMap {
        position: relative;
    }


    // @extend .text-white;
    .email a {
        color: $white !important;

        &:hover {
            color: $white !important;
            text-decoration: underline;
        }
    }

    .social {
        @extend .social-bg-light;

        a {
            @extend .mr-4;
            color: $color-primary !important;
        }
    }

    &.header-default {
        @extend .no-bg;
    }

    &.header-youTube {
        @extend .text-white;
        @extend .whiteLinks;
    }

    &.header-magazin {
        h1 {
            @extend .mt-2;
        }

        h4 {
            @extend .mb-5;
        }
    }

    &.header-person {
        @extend .text-white;
        @extend .whiteLinks;

        @extend .pt-0;
        @extend .pb-0;

        .height {
            min-height: 600px;
            background-size: cover;
            background-position: center center;
        }
    }
}

.section-header {
    position: relative;
    z-index: +1;

    * {
        color: $color-text;
    }
}
