// Google likes this:
@font-face {
    font-display: fallback;
}

.lead {
    margin-bottom: 1.5em;
    @extend .lead-2;

    @include media-breakpoint-down(md) {
        font-size: 1.25em !important;
    }
    @include media-breakpoint-down(sm) {
        font-size: 1em !important;
    }

    line-height: 1.5em;

    .card-body & {
        display: block;
        margin-bottom: 0.5em !important;
    }
}

.small {
    @extend .small-3;
}

.list-unstyled {
    ul {
        @extend .list-unstyled;
    }
}

main {
    ul {
        padding-left: 1.25em;
    }
}

.whiteLinks {
    color: $white;
}

ul {
    .list & {
        list-style-type: none;

        li:before {
            content: '\2014';
            position: absolute;
            margin-left: -20px;
        }
    }
}
