table {
    width: 100%;

    thead{
        th{
            color: $white;
            margin-bottom: .5em;
            background-color: $color-primary;
        }
    }

    tbody{
        tr{
            display: block;
            border-bottom: 1px solid $color-bg-dark;

            &.hide{
                display: none;
            }
        }
    }
}