footer {
    background: none !important;

    @extend .pt-0;

    .footer-content {
        @extend .text-white;
        .row {
            background: $color-bg-dark;
        }

        div[class^="col-"] {
            @extend .p-7;
        }

        a.nav-link {
            // text-decoration: underline;
            font-weight: 300;
            color: #c7c7c7 !important;
            &:hover {
                color: $white !important;
            }
        }
    }

    .footer-top {
        font-size: 16px;
        color: #282828;
        z-index: -1;
        position: relative;
        .row {
            background: $white;
            margin-bottom: 0 !important;
        }
        [class*='col-'] {
            border-right: 1px solid #c7c7c7;
            &:last-child {
                border-right: none;
            }
        }
    }

    .footer-info {
        @extend .text-white;
        .row {
            background: $color-primary;
        }
        color: $dark;
        @extend .mb-4;

        a {
            // color: $black !important;
            text-transform: none !important;

            &:hover {
                text-decoration: underline !important;
                // color: $color-primary !important;
            }
        }

        .col {
            @extend .p-6;

            &:nth-child(n+2) {
                border-left: 1px solid $black;
            }
        }
        p {
            margin-top: 1em;
        }
    }
}
