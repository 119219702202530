.blog-latest-articles {
    @extend .row;
    .card-wrapper {
        @extend .col-4;
    }
}

.card-wrapper {
    margin-bottom: 4em !important;
}

.card {
    border-radius: 0;
    
    @include media-breakpoint-down(md) {
        margin-bottom: 3em;
    }

    .link {
        position: absolute;
        z-index: +1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .card-title {
        color: $color-primary !important;
        h5 {
            font-size: .75em !important;
            color: $color-primary;
            font-weight: 600;
            margin-bottom: 0;
        }
        p { 
            font-size: .75em;
            color: $black;
        }
    }
    
}

.card-title {
    color: $color-primary !important;
    font-size: 1.25em;
}

.teamPlayer {
    @extend .card;
    @extend .text-white;
    @extend .bg-img;
    @extend .h-500;
    .media {
        font-size: 1.5em;
    }
}

.newsItem {
    @extend .col-6;
    @extend .col-lg-4;
}

.produkt {
    @extend .card;
    @extend .hover-shadow-6;
    min-height: 300px;
    .card-bottom {
        position: absolute;
        bottom: 1em;
    }
    .link {
        position: absolute;
        z-index: +1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.produktKarte {
    @extend .card;
    @extend .text-center;
    @extend .d-block;
}

.category {
    @extend .row;
    @extend .pb-3;
    .cat {
        @extend .col-10;
        letter-spacing: 1.87px;
        color: $black !important;
    }
    .icn {
        @extend .col-2;
        @extend .text-right;
    }
}

.portfolio-box {
    margin-bottom: 3em !important;
    img {
        margin-bottom: 1em;
    }
    .link {
        position: absolute;
        z-index: +1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}