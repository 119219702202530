.offcanvas {
    a {
        color: $black !important;
        text-transform: none !important;

        &:hover {
            color: $color-primary !important;
        }
    }
}

.offcanvas-info {
    @extend .text-center;
}