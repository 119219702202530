.section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.bg-dark {
  background-size: cover;
  @extend .text-white;
}

.vh-75 {
  height: 75vh;
}

.vh-66 {
  height: 66vh;
}

.vh-50 {
  height: 50vh;
}

section {
  &.background-dark {
    @extend .bg-dark;

    .people & {
    }

    .companies & {
    }
  }
}
