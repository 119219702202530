    // legend {
    //     display: none;
    // }

    // label {
    //     display: none;
    //     font-size: 18px;
    // }

    fieldset {
        .row {
            margin-bottom: 1.5em;
            padding-bottom: 1.5em;
            border-bottom: 1px solid $color-primary;
        }
    }

    input,
    textarea {
        @extend .form-control;
        @extend .text-dark;
        border: none;
        border: 1px solid $color-primary;

        // border-bottom: 1px solid $color-text;
        &.error {
            border-bottom-color: $color-primary;
        }

        margin-top: .75em;
    }

    .error,
    .help-inline {
        color: $color-primary;
    }

    textarea {
        border: 1px solid $color-primary;
        min-height: 120px;
    }

    .btn {
        @extend .button;
        float: right;
        background: $color-bg-dark;

        &:hover {
            background: $black;
        }
    }

    .button {
        &:hover {
            background: $black;
        }
    }

    .form-navigation {
        ul {
            list-style-type: none;
            padding: 0;
        }
    }

    // .mauticform-input,
    // .mauticform-textarea {
    //     @extend .form-control;
    //     border: none;
    //     border-bottom: 1px solid $color-text;
    // }

    // .mauticform-textarea {
    //     border: 1px solid $color-text;
    //     min-height: 120px;
    // }

    // .mauticform-page-wrapper {
    //     @extend .row;
    // }

    // .mauticform-row {
    //     @extend .form-group;
    //     margin-bottom: 1em;
    // }

    // .mauticform-button {
    //     background-size: cover;
    //     @extend .text-white;
    // }

    // .mauticform-has-error {
    //     border: 1px solid $color-primary;
    // }


    // .mauticform-errormsg {
    //     position: absolute;
    //     font-size: 12px;
    // }

    ul.inputs-list {
        @extend .list-unstyled;
        @extend .list-inline;
    }
