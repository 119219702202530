//
// Clients Grid //
//
.clients-grid {
	overflow: hidden;
	margin: 0;
	padding: 0;
	text-align: center;
	li {
		list-style-type: none;
		position: relative;
		float: left;
		width: 16.6%;
		margin: 0;
		padding: 30px;
		&:before, &:after {
			content: '';
			position: absolute;
		}
		&:before {
			top: 0;
			left: -1px;
			height: 100%;
			border-left: 1px solid #E2E2E2;
		}
		&:after {
			left: 0;
			bottom: -1px;
			width: 100%;
			border-bottom: 1px solid #E2E2E2;
		}
		a {
			img {
				opacity: 0.7;
				@include transition(ease-out 0.2s);
			}
			&:hover, &:focus {
				img {
					opacity: 1;
				}
			}
		}
    }
    
    
	@include media-breakpoint-down(lg) {
		li { width: 25%; }
	}
	@include media-breakpoint-down(md) {
		li { width: 33.3%; }
	}
	@include media-breakpoint-down(sm) {
		li { width: 50%; }
	}
	&.column-5 {
		li { width: 20%; }
		@include media-breakpoint-down(lg) {
			li { width: 25%; }
		}
		@include media-breakpoint-down(md) {
			li { width: 33.3%; }
		}
		@include media-breakpoint-down(sm) {
			li { width: 50%; }
		}
	}
	&.column-4 {
		li { width: 25%; }
		@include media-breakpoint-down(md) {
			li { width: 33.3%; }
		}
		@include media-breakpoint-down(sm) {
			li { width: 50%; }
		}
	}
	&.column-3 {
		li { width: 33.3%; }
		@include media-breakpoint-down(md) {
			li { width: 50%; }
		}
	}
	&.column-2 {
		li { width: 50%; }
	}
	//
	// Border Style - Solid //
	//
	&.border-style-solid {
		border: 0;
		li {
			&:before { border-left-style: solid; }
			&:after { border-bottom-style: solid; }
		}
	}
	//
	// Border Style - Dotted //
	//
	&.border-style-dotted {
		border: 0;
		li {
			&:before { border-left-style: dotted; }
			&:after { border-bottom-style: dotted; }
		}
	}
}