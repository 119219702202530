$dimensions: 14em;
.stoerer {
    position: absolute;
    z-index: +2;
    top: 11% !important;
    right: 7% !important;
    text-align: center;
    width: $dimensions;
    height: $dimensions;
    border-radius: $dimensions/2;
    padding: 1em;
    // border: 2px solid;
    background: $color-light;
    p {
        color: $black !important;
    }
    a {
        color: $white;
    }
    .fancy * {
        // font-family: $font-family-fancy;
        margin-bottom: 0;
    }
}