@import 'components/aos';
@import 'components/badges';
@import 'components/buttons';
@import 'components/box';
@import 'components/cards';
@import 'components/downloads';
@import 'components/forms';
@import 'components/footer';
@import 'components/gallery';
@import 'components/header';
@import 'components/headings';
@import 'components/helper';
@import 'components/hr';
@import 'components/imageeffects';
@import 'components/jonnitto_assetlist';
@import 'components/layout';
@import 'components/logogrid';
@import 'components/navigation';
@import 'components/offcanvas';
@import 'components/pagination';
@import 'components/sections';
@import 'components/tables';
@import 'components/shuffle';
@import 'components/slickslider';
@import 'components/stoerer';
@import 'components/tabs';
@import 'components/teaser';
@import 'components/typography';


@import 'navigation';
@import 'search';
@import 'table';
@import 'map';

// Write your custom style. Feel free to split your code to several files

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  color: $color-text;
  background-color: #efefef;
}


// .wrapper {
//   //overflow: hidden !important;
//   max-width: 1600px;
//   width: 100%;
//   margin: 0 auto;
// }

.rounded-circle {
  img {
    @extend .rounded-circle;
  }
}
.lead-container .lead {
  padding: 2em !important;
}
.header .textBox {
  padding: 2em 2.75em 2.15em !important;
}
@include media-breakpoint-down(md) {
  .lead-container .lead {
    padding: 1.5em !important;
  }
  .header .textBox {
    padding: 1em 2.25em 1.75em!important;
  }
}
@include media-breakpoint-down(sm) {
  .lead-container .lead {
    padding: 1.5em !important;
  }
  .header .textBox {
    padding: .75em 1.75em 1.5em!important;
  }
}




label{
  font-size: 16px;
}

@include media-breakpoint-down(sm) {
  #mitglied-werden{
    .row{
      .col:first-child{
        padding-bottom: 1em;
      }
      .col:last-child{
        padding-top: 1em;
      }
    }
  }
}