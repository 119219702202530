
.latest-news-list-item {
    a.link-node {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.teaser,
.slick-slide {
    .item {
        .textBox {
            position: absolute;
            bottom: 0;
            left: 0;
            color: $black;
            background: $white;
            opacity: 0.9;
            @extend .p-5;
            @extend .col-9;
            font-size: 1em;
        }

        &.isLocked {
            .badge {
                border-radius: 0;
                position: absolute;
                top: 1em;
                right: 1em;
                z-index: 9;
            }
        }

        & .h-2 {
            .textBox {
                padding: 1.5em !important;
            }
        }
    }

    h3 {
        font-size: 1em;
        font-weight: 300;
        color: $color-primary;
    }
}

.textBox {
    z-index: 4;

}

.h-2 {
    .textBox {

        bottom: 3.5em !important;
        left: 2em !important;
        font-size: 1.5em !important;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            bottom: 4.75em !important;
            font-size: 1em !important;
            line-height: 1.5em;
        }

        @include media-breakpoint-down(sm) {
            bottom: 4em !important;
            left: 1em !important;
            font-size: 16px !important;
            min-width: 91% !important;
        }
    }

}
