.box {
    // @extend .box-shadow;
    // @extend .block;
    @extend .card;
    @extend .p-4;
    background-color: $color-bg-light;
    background-size: cover;

    &[data-overlay] {
        background-color: #fff !important;
    }

    .box-content {
        @extend .h-100;
        z-index: +1;
        position: relative;
    }
    
    .bottomRight {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.boxed {
    @extend .shadow-4;
}

.textBox {
    .header & {
        z-index: +1;
        @extend .col-lg-8;
        @extend .offset-lg-2;
        @extend .col-md-10;
        @extend .offset-md-1;
        @extend .text-dark;
        @extend .pb-7;
        @extend .pt-7;
        @extend .px-5;
        @extend .align-self-end;

        @include media-breakpoint-down(sm) {
            padding-top: 25px !important;
            padding-bottom: 25px !important;
        }
    }

    section.box &,
    .teamBox & {
        font-weight: 300;
        z-index: +1;
        @extend .col-8;
        @extend .ml-0;
        bottom: 0;
        @extend .text-dark;
        @extend .pb-7;
        @extend .pt-5;
        @extend .px-5;
        left: 0;
        position: absolute;
        // @extend .align-self-end;
        h1 {
            font-size: 24px;
            line-height: 31px;
        }
    }

    .teamBox & {
        @extend .col-10;
        font-size: .85em;
        padding-bottom: 1.5em !important;
    }

    section.box.h-2 & {
        margin-bottom: initial !important;            
        margin-left: 2em !important;            
        bottom: 3em !important;
        h1 {
            font-size: 36px;
            line-height: 46px;
        }
    }

    background-color: rgba(255,255,255,0.95);

    h1 {
        @extend .mb-0;
        @extend .mt-0;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px !important;
        line-height: 1.5em;
    }

}