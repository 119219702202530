.newsListItem,
.teamBox {
    &:hover {
        .button-square {
            margin-right: -1em;
        }
        .borderline {
            margin-left: 1em;
        }
        .textBox {
        }
    }
    &:before {
        mix-blend-mode: color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: black;
        opacity: .5;
        z-index: +1;
        content: '';
    }
}