// Mixins
@import 'navigation/mixins';

// Variables
@import 'navigation/variables';

// navigation
@import 'navigation/_navigation.core';
@import 'navigation/_navigation.brand.text';
@import 'navigation/_navigation.logo';
@import 'navigation/_navigation.toggler.button';
@import 'navigation/_navigation.body';
@import 'navigation/_navigation.menu';
@import 'navigation/_navigation.menu.social';
@import 'navigation/_navigation.submenu.indicator';
@import 'navigation/_navigation.button';
@import 'navigation/_navigation.text';
@import 'navigation/_navigation.badge';
@import 'navigation/_navigation.inline.form';
@import 'navigation/_navigation.dropdown';
@import 'navigation/_navigation.dropdown.horizontal';
@import 'navigation/_navigation.megamenu';
@import 'navigation/_navigation.megamenu.grid';
@import 'navigation/_navigation.megamenu.list';
@import 'navigation/_navigation.tabs';
@import 'navigation/_navigation.transparent';
@import 'navigation/_navigation.fullscreen';

// Skins 
// Uncomment one of the lines below to include a skin in the final CSS file (navigation.css)

//@import 'navigation/skins/_navigation.border.top';
//@import 'navigation/skins/_navigation.border.bottom';
//@import 'navigation/skins/_navigation.border.top.bottom';
//@import 'navigation/skins/_navigation.boxed';
//@import 'navigation/skins/_navigation.rounded.boxed';
//@import 'navigation/skins/_navigation.colored';
//@import 'navigation/skins/_navigation.gradient';
//@import 'navigation/skins/_navigation.mini.circle';
//@import 'navigation/skins/_navigation.bottom.arrow';
//@import 'navigation/skins/_navigation.dark';

// Utilities
@import 'navigation/_utilities';


.nav-wrapper {


    .top-nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        color: #555d65;
        font-size: 14px;
        background-color: #fff;
    }

    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);

    .social-links {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .social-links li {
        display: inline-block;
    }

    .social-links li a {
        margin: 0 0 0 15px;
        font-size: 16px;
        color: #555d65;
    }


}


.navigation {

    .navigation-item{
        word-break: keep-all;
    }
    
    .navigation-link {
        font-size: 1.05rem;
        font-weight: 300;
        color: $color-text-darker;

        &.is-normal,
        &:visited {
            color: rgba($color-text-darker, 1) !important;

            &:hover {
                color: $color-primary !important;
            }

        }

        &.is-active,
        &.is-current {
            color: $color-primary !important;

            &:hover {
                color: $color-primary !important;
            }
        }
    }

    .navigation-dropdown-link {

        &:hover,
        &.is-active,
        &.is-current {
            color: $color-primary !important;

            &:hover {
                color: $color-primary !important;
            }
        }
    }

    .navigation-megamenu-container {
        a {
            font-size: 1em;
        }
    }

    .navigation-list-heading {
        font-size: 1.25rem;
        text-transform: initial;
    }

    .navigation-list {
        li a {
            padding-left: 17px;

            &:before {
                display: none;
            }
        }
    }

}

.navigation-logo {
    margin-left: 1em;
}

.navigation-button-toggler {
    position: absolute;
    right: 1em;
}

.navigation-body-header {
    border-bottom: none;
}

@include media-breakpoint-down(md) {
    ul.navigation-dropdown {
        background-color: #fff !important;
    }
}
