// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.

// Google fonts
@import url("https://use.typekit.net/zdu8bnw.css");


//----------------------------------------
// Grid Settings
//----------------------------------------
$grid-gutter-width: 30px !default;
$grid-item-height: 290px;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1280px,
  xl: 1680px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1440px,
  xl: 1620px
) !default;



//----------------------------------------
// Header Dimensions
//----------------------------------------
$header-height: 100px;
$bottom-header-height: 40px;

//----------------------------------------
// Color system
//----------------------------------------
$blue:                            #50a1ff;
$indigo:                          #6610f2;
$purple:                          #926dde;
$pink:                            #e83e8c;
$red:                             #D31318;
$orange:                          #ffbe00;
$yellow:                          #ffba00;
$green:                           #3cd458;
$teal:                            #20c997;
$cyan:                            #17a2b8;

// Context colors
$color-primary:                   #D31318;
$color-secondary:                 rgba(255,249,172,1);
$color-success:                   $green;
$color-info:                      $purple;
$color-warning:                   $yellow;
$color-danger:                    $color-primary;
$color-light:                     #f8f9fa;
$color-dark:                      #191919;




//----------------------------------------
// Background colors
//----------------------------------------
$color-bg-lightest:               #fcfdfe;
$color-bg-lighter:                #f9fafb;
$color-bg-light:                  #f5f6f7;
$color-bg-body:                   #ffffff;
$color-bg-dark:                   #282828;
$color-bg-gray:                   #fafbfb;


//----------------------------------------
// Text colors
//----------------------------------------
$color-text-darker:               #333333;
$color-text-dark:                 #7C7C7C;
$color-text:                      #000000;
$color-text-light:                #999999;
$color-text-lighter:              #bfc5ca;
$color-text-lightest:             #d3d3d3;
$color-text-secondary:            #929daf;
$color-text-disable:              #a5b3c7;
$color-text-placeholder:          #c9ccce;
$color-title:                     $color-text-darker;
$color-subtitle:                  $color-text-light;


//----------------------------------------
// Fonts
//----------------------------------------
$fonts-dir:                       '../fonts/';
$font-family-base:                ronnia, sans-serif;
$font-family-title:               ronnia, sans-serif;

$font-size-base:                  1.125rem;

$font-weight-base:                300;
$line-height-base:                1.5;

$h1-font-size:                    $font-size-base * 2.5;
$h2-font-size:                    $font-size-base * 2;
$h3-font-size:                    $font-size-base * 1;
$h4-font-size:                    $font-size-base * 1;
$h5-font-size:                    $font-size-base * 1;
$h6-font-size:                    $font-size-base * 1;

$h1-font-weight:                  600;
$h2-font-weight:                  600;
$h3-font-weight:                  600;
$h4-font-weight:                  300;
$h5-font-weight:                  300;
$h6-font-weight:                  300;

$display1-size:                   5rem;
$display2-size:                   4rem;
$display3-size:                   3.5rem;
$display4-size:                   3rem;

$display1-weight:                 300;
$display2-weight:                 300;
$display3-weight:                 300;
$display4-weight:                 300;


//----------------------------------------
// Navbar
//----------------------------------------
$navbar-min-height:               56px;
$navbar-offset-top:               10px;


//----------------------------------------
// Other variables
//----------------------------------------

$zindex-offcanvas:                  10100 !default;

// Feel free to override other variables here. You can see the list of
// all the available variables in `/plugin/thesaas/scss/_variables.scss`
