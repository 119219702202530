.content-box {
    @include make-row();
}

.col-icons {
    @include media-breakpoint-up(md) {
        @include make-col(2);
    }
}

.col-content {
    @include media-breakpoint-up(md) {
        @include make-col(8);
    }
    @extend .order-md-1;
}


.h-10 {
    height: 10vh !important;
}

.h-20 {
    height: 20vh !important;
}

.h-30 {
    height: 30vh !important;
}

.h-40 {
    height: 40vh !important;
}

.h-50 {
    height: 50vh !important;
}

.h-60 {
    height: 60vh !important;
}

.h-70 {
    height: 70vh !important;
}

.h-80 {
    height: 80vh !important;
}

.h-90 {
    height: 90vh !important;
}


.h-25 {
    height: 25vh !important;
}

.h-75 {
    height: 75vh !important;
}


.h-33 {
    height: 33vh !important;
}

.h-66 {
    height: 66vh !important;
}


.block-item {
    height: $grid-item-height;
    margin-bottom: $grid-gutter-width;
    &.h-2 {
        height: $grid-item-height*2 + $grid-gutter-width;
    }
  }
   