.content-tabs {
    .nav-item {
        padding: .35em 0;
        border-bottom: 1px solid #333;
    }
    .nav-link {
        color: #333;
        &.active {
            color: $color-primary;
            font-weight: bold;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: .75em;
    }

}