
.editPopup {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border: 2px solid #f09;
    background: $white;
    font-size: 12px;
    padding: 2px 6px;

    a {
        color: #f09;
    }
}

.editBorder {
    border: 2px solid #f09;
    background: $white;
    position: relative;
    .edit_label {
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
        background: #f09;
        font-size: 12px;
        padding: 2px 6px;
    }
    padding: 2px 6px;
}
