.Pagination{
    width: 100%;
    position: relative;
    list-style-type: none;
    text-align: center;
    margin: 0 auto;

    .Pagination-item {
        width: 40px;
        height: 40px;
        display: inline;
        padding: 10px 20px;
        position: relative;
    }
}