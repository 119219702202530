.search {
    
    .meta-navigation & {
        display: none;

        input {
            @extend .form-control;

            top: 6px;
            right: 35px;
            width: 225px;
            height: 1.5em;
            margin: 0;
            z-index: 10;
            position: absolute;
        }

        &[style*="block"] + .search-trigger{
            padding-top: 0;
        }

        label,
        button[type="submit"] {
            display: none;
        }
    }



    label,
    input,
    button{
        float: left;
        position: relative;
    }

    label{
        padding: .5em 0;
        font-size: 18px;
        font-weight: 300;
    }

    input{
        width: 300px;
        margin: 0 1em;
        font-size: 18px;
    }

    button[type="submit"] {
        display: block;
        padding: 3px 16px 3px;
    }
}

.es-results {
    margin-top: 1em;

    ol:not(.breadcrumb) {
        @extend .mt-5;
        @extend .mb-5;
        padding: 0;
        list-style-type: none;

    }

    li {
        @extend .mb-4;
        padding: 1em;
        background-color: $white;

        p {
            margin-bottom: 0;
        }
    }

    em {
        color: $color-primary;
        font-style: normal;
    }

    .breadcrumb {
        margin: 0;
        padding: 0;
        font-size: 0.8em;

        li.breadcrumb-item{
            margin: 0 !important;
            padding: 0.5em 0.25em 0.5em 0.5em;

            &:first-child{
                padding-left: 0;
            }

            &:before{
                margin: 0 0.75rem 0 0;
            }
        }

        span {
            @extend .mr-1;
        }
        a {
            color: #6c757d;
        }
    }
}

.page-navigation {
    ul {
        padding: 0;

        li {
            display: inline-block;
            padding: 0.5em;
            @extend .mr-4;

        }

        li:not(.next):not(.previous) a{

            &:before{
                height: 100%;
                width: auto;
                content: '';
                display: inline;
                padding: 0 .25em 0 0;

                [lang="de"] &{
                    content: 'Seite ';
                }
                [lang="fr"] &{
                    content: 'Page ';
                }
                [lang="it"] &{
                    content: 'Pagina ';
                }
            }
        }

        li.current:not(.next):not(.previous) a{
            color: $color-text;
        }
    }
    @extend .mb-5;
}