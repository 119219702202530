.sharethis-inline-share-buttons {
    position: sticky;
    top: 120px;
}

.btn-primary,
.btn-outline-primary {
    border: none;
    // border-radius: 0;
    letter-spacing: .05em !important;
    font-size: 1em;
    font-weight: 300;

    a {
        color: $color-light;
    }
}

.btn-outline-primary {
    border: 2px solid $color-dark;
    background: none;

    a {
        color: $color-dark;
    }

    &:hover {
        a {
            color: $color-light;
        }
    }

    .text-white & {
        border-color: $color-light;

        a {
            color: $color-light;
        }
    }
}


$squareButtonSize: 60px;
$textButtonSize: 50px;

.button {
    float: initial !important;

    &.button-history {
        float: right !important;
        &:hover {
            cursor: pointer;
        }
    }
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;
    background: $color-primary !important;

    text-transform: initial;

    font-size: 1rem !important;

    &:hover {
        color: $white !important;
        // background: adjust-hue($color-primary, -35deg);
    }

    border-radius: 0;

    &.outline {
        @extend .btn-outline-primary;
    }

    &.borderline {
        position: absolute;
        z-index: +999;
        bottom: -$textButtonSize;
        left: 0;
        height: $textButtonSize;
    }

    &.button-square {
        width: $squareButtonSize;
        height: $squareButtonSize;
        @extend .p-0;
        position: absolute;
        z-index: +99999;
        bottom: 0;
        right: -$squareButtonSize;
    }
}
