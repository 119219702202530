.h1 {
    @extend h1;
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}

.h4 {
    @extend h4;
}

.h5 {
    @extend h5;
}

.h6 {
    @extend h6;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    // color: $color-primary;
 //   span {
   //     color: $black;
   // }
}

h1, .h1, h2, .h2 {
  line-height: 1.2em;
}

h1, .h1,
h2, .h2 {
  color: $color-primary;
}


h4, .h4 {
  // font-family: 'Roboto Condensed';
  // text-transform: initial;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    line-height: 1.1em;
}

.section-header {
  h2 {
    // @extend .display-2;
    margin-bottom: 1em;
  }
    text-align: left;
    max-width: 100%;
    margin: 0 auto !important;
}

// .card-title {
//   color: $black !important;
//   p {
//     font-size: 1.25em !important;
//   }
//   h5 {
//     color: $color-primary;
//     font-size: 1.25em !important;
//     font-weight: 600;
//     margin-bottom: 0;
//   }
// }


@include media-breakpoint-down(sm) {

  h1, .h1 {
    font-size: 32px !important;
  }

  h2, .h2 {
    font-size: 24px !important;
  }     

  h3, .h3 {
    font-size: 24px !important;
  }        
  
  h4, .h4 {
    font-size: 16px !important;
  }        

}