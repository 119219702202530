.slick-slide {
    .item {
        height: 700px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        @include media-breakpoint-down(md) {
            height: 450px;
        }
    }
}

.slick-dots {
    z-index: 9;
}