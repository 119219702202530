.rule {
    @extend .mt-0;
    @extend .ml-0;
    @extend .mb-7;
    width: 75px !important;
    border-top-width: 2px;

    .bg-dark & {
        border-top-color: #9B9B9B;
    }

}