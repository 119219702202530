// .assets-list {
//     .assets-list__img {
//         // width: 80% !important;
//         // padding: 2em;
//     }

//     .assets-list__link--xls,
//     .assets-list__link--pdf {
//         .assets-list__img {
//             margin: 0 auto;
//             padding: .5em 0;
//         }
//     }

//     .assets-list__link--xls {
//         .assets-list__img {
//             padding: .5em 0;
//         }
//     }

//     .assets-list__entry {
//         figure {
//             margin: initial;
//         }
//     }

// }


.assets-list {
    margin-top: 0
}

.assets-list__entry,
.assets-list__list {
    list-style: none;
    margin: 0;
    padding: 0
}

.assets-list__entry {
    padding-bottom: 1rem
}

.assets-list__headline {
    margin: 0;
    display: block
}

.assets-list__figure {
    margin-bottom: .5em
}

.assets-list__figure:last-child {
    margin-bottom: 0
}

.assets-list__filesize:empty {
    display: none
}

.assets-list__filesize:before {
    content: "("
}

.assets-list__filesize:after {
    content: ")"
}

.assets-list__action {
    font-size: .75rem;
    display: block
}

.assets-list__link--text {
    display: inline-block
}

.assets-list__list--thumbnail {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: -15px;
    margin-right: -15px
}

.assets-list__entry--thumbnail {
    padding: 0 15px 30px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px
}

.assets-list__link--thumbnail {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end
}

.assets-list__figure {
    position: relative;
    display: block;
    overflow: hidden;

    &:before {
        content: "";
        padding-top: 75%;
        display: block
    }
}

.assets-list__img {
    position: absolute;
    // display: block;
    top: 50%;
    max-width: 80% !important;
    max-height: 80% !important;
    width: auto;
    height: auto;
    left: 0;

    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    img {
        @extend .shadow-9;
    }
}

@media (min-width:420px) {
    .assets-list__entry--thumbnail {
        // flex: 0 0 50%;
        // max-width: 50%
    }
}

@media (min-width:576px) {
    .assets-list__entry--thumbnail {
        // flex: 0 0 33%;
        // max-width: 33%
    }
}
